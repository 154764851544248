// This file is used to create a dynamic sizes string for a picture element working with srcsets and our breakpoints defined in the _variables file
// It takes an object with the following structure:
// {
//   max: {
//     size: 200,
//   },
//   lg: {
//     modifier: 3,
//   },
// }
//
// We use size if are certain an image has a fixed size for a viewport and modifier if we want to use a fraction of the viewport width

import cssVars from "~/assets/export.module.scss"

interface CssVars {
  [key: string]: number
}

const cssVarsInt: CssVars = Object.fromEntries(
  Object.entries(cssVars).map(([key, value]) => [key, parseInt(value)]),
)

interface SizesData {
  [key: string]: {
    modifier?: number | string
    spacing?: number
    size?: number
  }
}

const BPORDER = ["max", "lg", "md", "m", "sm", "mini"]
const VALID_BP_PROPS = ["modifier", "spacing", "size"]

interface Map {
  [key: string]: number
}

const VIEWPORTS_MAP: Map = {
  max: cssVarsInt.contentMaxWidth,
  lg: cssVarsInt.lg,
  md: cssVarsInt.md,
  m: cssVarsInt.m,
  sm: cssVarsInt.sm,
  mini: cssVarsInt.mini,
}

const PAGEPADDING_MAP: Map = {
  max: cssVarsInt.pagePadding,
  lg: cssVarsInt.pagePadding,
  md: cssVarsInt.pagePadding,
  m: cssVarsInt.pagePadding,
  sm: cssVarsInt.pagePadding,
  mini: cssVarsInt.pagePaddingMini,
}

const sanityCheck = (data: SizesData) => {
  if (!data) {
    throw new Error("createSizesString did not receive any data")
  }
  Object.keys(data).forEach((key) => {
    if (data[key]?.size && typeof data[key].size !== "number") {
      throw new TypeError("Size must be a number")
    }
    if (data[key].size && data[key].modifier) {
      throw new Error("can't combine modifier and size, use only one of them")
    }
    if (!BPORDER.includes(key)) {
      throw new Error(
        "createSizesString did receive a breakpoint name it can not work with:",
      )
    }
    Object.keys(data[key]).forEach((prop) => {
      if (!VALID_BP_PROPS.includes(prop)) {
        throw new Error(
          `"${prop}" is not a valid key. Use one of: [${VALID_BP_PROPS}]`,
        )
      }
    })
  })
}

export const createMediaString = (bp: string): string => {
  return bp === "mini" ? "" : `(min-width: ${VIEWPORTS_MAP[bp]}px) `
}

const createSizesString = (sizesData: SizesData) => {
  sanityCheck(sizesData)

  return Object.keys(sizesData)
    .sort((a, b) => {
      return BPORDER.indexOf(a) - BPORDER.indexOf(b)
    })
    .map((bp) => {
      const media = createMediaString(bp)
      const { modifier, size, spacing } = sizesData[bp]

      const finalSize = spacing && size ? size + spacing : size
      const finalCalc = spacing
        ? `(100vw - ${PAGEPADDING_MAP[bp] * 2 + spacing}px)`
        : `(100vw - ${PAGEPADDING_MAP[bp] * 2}px)`

      const value = size ? `${finalSize}px` : `calc(${finalCalc} / ${modifier})`
      return media + value
    })
    .join(", ")
}
export default createSizesString
